<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div>

  <div ngbDropdown
       (openChange)="toggled.emit($event)">

    <div class="input-group-prepend" *ngIf="label">
      <span id="dsSelectMenuLabel" class="input-group-text">
        {{ label | translate }}
      </span>
    </div>

    <button aria-describedby="dsSelectMenuLabel"
            id="dsSelectMenuButton"
            class="btn btn-outline-primary selection mw-100 text-truncate"
            (blur)="close.emit($event)"
            (click)="close.emit($event)"
            [disabled]="disabled"
            ngbDropdownToggle>
      <ng-content select=".selection"></ng-content>
    </button>

    <div>
      <ng-content select=".search"></ng-content>
    </div>

    <div ngbDropdownMenu
         (click)="close.emit($event)"
         class="dropdown-menu"
         id="dsSelectDropdownMenu"
         aria-labelledby="dsSelectMenuButton">
      <div aria-labelledby="dropdownMenuButton">
        <ng-content select=".menu"></ng-content>
      </div>
    </div>
  </div>

</div>
