<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer  id="iiif-viewer"
                        [object]="object"
                        [searchable]="iiifSearchEnabled"
                        [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    {{'publication.page.titleprefix' | translate}}<ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2 d-flex flex-row">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'publication.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ng-container *ngIf="!mediaViewer.image">
      <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
        <ds-thumbnail [thumbnail]="object?.thumbnail | async"></ds-thumbnail>
      </ds-metadata-field-wrapper>
    </ng-container>
    <ng-container *ngIf="mediaViewer.image">
      <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
    </ng-container>
    <ds-themed-item-page-file-section [item]="object"></ds-themed-item-page-file-section>
    <ds-metadata-representation-list class="ds-item-page-mixed-author-field"
                                     [parentItem]="object"
                                     [itemType]="'Person'"
                                     [metadataFields]="['dc.contributor.author']"
                                     [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.date.embargo']"
                                [label]="'publication.page.embargodate'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.advisor']"
                                [label]="'publication.page.advisor'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.college']"
                                [label]="'publication.page.college'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.contributor.department']"
                                [label]="'publication.page.department'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['thesis.degree.level']"
                                [label]="'publication.page.degreelevel'">
    </ds-generic-item-page-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.publisher']"
                                [label]="'publication.page.publisher'">
    </ds-generic-item-page-field>
    <ds-item-page-date-field [item]="object"></ds-item-page-date-field>
    <div>
      <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
        <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
<!--    <ds-related-items-->
<!--      [parentItem]="object"-->
<!--      [relationType]="'isProjectOfPublication'"-->
<!--      [label]="'relationships.isProjectOf' | translate">-->
<!--    </ds-related-items>-->
<!--    <ds-related-items-->
<!--      [parentItem]="object"-->
<!--      [relationType]="'isOrgUnitOfPublication'"-->
<!--      [label]="'relationships.isOrgUnitOf' | translate">-->
<!--    </ds-related-items>-->
<!--    <ds-related-items-->
<!--      [parentItem]="object"-->
<!--      [relationType]="'isJournalIssueOfPublication'"-->
<!--      [label]="'relationships.isJournalIssueOf' | translate">-->
<!--    </ds-related-items>-->
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
    <ds-generic-item-page-field [item]="object"
                                [fields]="['dc.subject']"
                                [separator]="','"
                                [label]="'item.page.subject'">
    </ds-generic-item-page-field>
    <ng-container *ngIf="(object.metadata['dc.rights'] && object.metadata['dc.rights.uri']); else License">
      <ds-cc-license [item]="object"></ds-cc-license>
    </ng-container>
    <ng-template #License>
      <ds-item-page-uri-field [item]="object"
                              [fields]="['dc.rights.license']"
                              [label]="'publication.page.rights'"
                              [defaultLabel]="'publication.default.license'">
      </ds-item-page-uri-field>
    </ng-template>
    <ds-item-page-uri-field [item]="object"
                            [fields]="['dc.identifier.uri']"
                            [label]="'publication.page.uri'">
    </ds-item-page-uri-field>
    <ds-publication-csl-citation [item]="object"></ds-publication-csl-citation>
    <ds-item-page-collections [item]="object"></ds-item-page-collections>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
