<footer class="text-lg-start">
    <div class="row" id="pre-footer">
        <div class="container">
            <div class="row">
                <div id="pre-footer-deets">
                    <div class="blocks hidden-xs col-sm-2">
                        <div class="block">
                            <img style="float: left; width: 53px; height: 53px;" src="assets/images/uprGRIC.png">
                        </div>
                    </div>
                    <ul class="links col-sm-10">
                        <li>
                            <a target="_blank" href="https://uprm.libwizard.com/f/studentworks"
                                title="Propose Content">{{'footer.purpose' | translate}}</a>
                        </li>
                        <li>
                            <a target="_blank" href="https://libguides.uprm.edu/repositorioUPRM "
                                title="Learn More (footer)">{{'footer.learn' | translate}}</a>
                        </li>
                        <li>
                            <a href="/info/feedback" title="Homepage UPR Feedback">{{'footer.feedback' | translate}}</a>
                        </li>
                        <li>
                            <a target="_blank" [href]="'footer.contact.url' | translate">{{'footer.contact' |
                                translate}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div id="bottom-bar" class="container solid-bg" role="contentinfo">
        <div class="wf-wrap">
            <div class="wf-container-bottom">
                <div class="wf-table wf-mobile-collapsed">
                    <div id="branding-bottom" class="wf-td"><a href="https://www.upr.edu/biblioteca-rum/"><img
                                class=" preload-me" src="assets/images/footericon.png" width="50" height="50"
                                sizes="50px" alt="Biblioteca UPRM"></a></div>
                    <div class="wf-td bottom-text-block">
                        <p>{{'footer.rights' | translate}}</p>
                    </div>
                </div>
            </div><!-- .wf-container-bottom -->
        </div><!-- .wf-wrap -->
    </div>
</footer>
