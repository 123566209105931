/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { AtmireAppRoutingModule } from './atmire-app-routing.module';
import {
  AtmireObjectCollectionModule
} from './atmire-object-collection/atmire-object-collection.module';
import { AtmireSavedItemListStoreService } from './atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { AtmireSavedItemListDataService } from './atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { LinkService } from '../app/core/cache/builders/link.service';
import { AtmireLinkService } from './core/cache/builders/atmire-link.service';
import { AtmireAuthService } from './core/auth/atmire-auth.service';
import { AtmireSharedModule } from './shared/atmire-shared.module';
import { AtmireItemDataService } from './core/data/atmire-item-data.service';
import { SearchTermsDataService } from './atmire-cua/search-reports/search-reports-service/search-terms-data.service';
import { SearchReportsDataService } from './atmire-cua/search-reports/search-reports-service/search-reports-data.service';
import { SharedStatletsModule } from './atmire-cua/statlets/shared/shared-statlets.module';
import { AtmireValuePairDataService } from './atmire-cua/shared-reports/value-pairs/atmire-value-pair-data.service';
import { AtmireSavedItemListAuthenticatedGuard } from './atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-authenticated.guard';
import { CslModule } from './csl.module';

/**
 * Declaration needed to make sure all decorator functions are called in time
 */
export const MODELS = [
];

const DECLARATIONS = [
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
];

const PROVIDERS = [
  { provide: LinkService, useClass: AtmireLinkService },
  AtmireSavedItemListStoreService,
  AtmireSavedItemListDataService,
  AtmireAuthService,
  AtmireItemDataService,
  SearchTermsDataService,
  SearchReportsDataService,
  AtmireValuePairDataService,
  AtmireSavedItemListAuthenticatedGuard,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedStatletsModule,
    AtmireAppRoutingModule,
    AtmireObjectCollectionModule,
    CslModule,
    AtmireSharedModule,
  ],
  exports: [
    ...DECLARATIONS,
  ]
})
export class AtmireAppModule {
}
