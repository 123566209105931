import { ThemedComponent } from '../theme-support/themed.component';
import { AuthNavMenuComponent } from './auth-nav-menu.component';
import { Component } from '@angular/core';

@Component({
  selector: 'ds-themed-auth-nav-menu',
  templateUrl: '../theme-support/themed.component.html',
  styleUrls: [],
})
export class ThemedAuthNavMenuComponent extends ThemedComponent<AuthNavMenuComponent> {
  protected getComponentName(): string {
    return 'AuthNavMenuComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/auth-nav-menu/auth-nav-menu.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import(`./auth-nav-menu.component`);
  }

}
