<header class="header">
  <div class="top-bar">
    <div class="container solid-bg">
      <div class="left-widgets mini-widgets"><span
          class="mini-contacts address icon-off show-on-desktop near-logo-first-switch in-menu-second-switch first">Recinto
          Universitario de Mayagüez, Call Box 9000 Mayagüez, PR 00681</span><span
          class="mini-contacts phone icon-off show-on-desktop near-logo-first-switch in-menu-second-switch">(787) 832-4040
          ext. 3810, 2151, 2155</span><span
          class="mini-contacts email icon-off show-on-desktop near-logo-first-switch in-menu-second-switch last">library@uprm.edu</span>
      </div>
      <div class="right-widgets mini-widgets"></div>
    </div>
  </div>
  <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="container navbar navbar-expand-md px-0">
    <div class="d-flex flex-grow-1">
      <a class="navbar-brand m-2" routerLink="/home">
        <img src="assets/images/upr.png" [attr.alt]="'menu.header.image.logo' | translate"/>
      </a>
    </div>
    <div class="d-flex flex-grow-1 ml-auto justify-content-end align-items-center">
      <ds-search-navbar class="navbar-search"></ds-search-navbar>
      <ds-lang-switch></ds-lang-switch>
      <ds-auth-nav-menu></ds-auth-nav-menu>
      <ds-impersonate-navbar></ds-impersonate-navbar>
      <div class="pl-2">
        <button class="navbar-toggler" type="button" (click)="toggleNavbar()"
                aria-controls="collapsingNav"
                aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
          <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </nav>
  <ds-themed-navbar></ds-themed-navbar>

</header>
<div class="slideout" aria-label="Pregúntanos por Chat / Ask Us" #slideout>
  <div class="tab_overlay" style="display: none;"></div>
  <div class="chat"><a class="popout" href="#" (click)="openPopUp($event)">Pregúntanos por Chat / Ask Us</a></div>
  <div class="lcs_load" aria-hidden="false" #load><iframe id="iframe_1f64a583eb0e64268356a53fe44cc4ff"
      name="iframe_1f64a583eb0e64268356a53fe44cc4ff"
      src="https://virtualref.uprm.edu/chat/widget/1f64a583eb0e64268356a53fe44cc4ff?referer=https%3A%2F%2Fscholar.uprm.edu%2F&amp;referer_title=UPRM%20Repository&amp;auth_id=0"
      title="Chat Widget" scrolling="no" style="width: 100%; height: 100%;">Content is loading...</iframe></div>
</div>
