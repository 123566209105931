<h5 class="simple-view-element-header">{{ 'publication.page.rights' | translate }}</h5>
<div class="full">
  <div class="license-img">
    <a rel="license"
       href="{{ccLicenseURI}}"
       title="{{ccLicenseName}}">
      <img src="{{imgSource}}">
    </a>
  </div>
  <div class="license-text">
    {{'publication.page.ccLicenseText' | translate}}{{ccLicenseText}}
  </div>
</div>

