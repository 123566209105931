import { Component, Injector } from '@angular/core';
import { slideMobileNav } from '../shared/animations/slide';
import { MenuComponent } from '../shared/menu/menu.component';
import { MenuService } from '../shared/menu/menu.service';
import { MenuID, MenuItemType } from '../shared/menu/initial-menus-state';
import { HostWindowService } from '../shared/host-window.service';
import { BrowseService } from '../core/browse/browse.service';
import { ActivatedRoute } from '@angular/router';
import { AuthorizationDataService } from '../core/data/feature-authorization/authorization-data.service';
import { OnClickMenuItemModel } from '../shared/menu/menu-item/models/onclick.model';
import { TextMenuItemModel } from '../shared/menu/menu-item/models/text.model';
import { LinkMenuItemModel } from '../shared/menu/menu-item/models/link.model';
import { getFirstCompletedRemoteData } from '../core/shared/operators';
import { PaginatedList } from '../core/data/paginated-list.model';
import { BrowseDefinition } from '../core/shared/browse-definition.model';
import { RemoteData } from '../core/data/remote-data';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends MenuComponent {
  /**
   * The menu ID of the Navbar is PUBLIC
   * @type {MenuID.PUBLIC}
   */
  menuID = MenuID.PUBLIC;

  constructor(protected menuService: MenuService,
              protected injector: Injector,
              public windowService: HostWindowService,
              public browseService: BrowseService,
              public authorizationService: AuthorizationDataService,
              public route: ActivatedRoute
  ) {
    super(menuService, injector, authorizationService, route);
  }

  ngOnInit(): void {
    this.createMenu();
    super.ngOnInit();
  }

  /**
   * Initialize all menu sections and items for this menu
   */
  createMenu() {
    const menuList: any[] = [
      {
        id: 'library',
        active: false,
        visible: true,
        index: 1,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.library'
        } as TextMenuItemModel
      },
      {
        id: 'library_UPRM',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.uprm',
          function: () => this.openExternalURL('https://www.upr.edu/biblioteca-rum')
        } as OnClickMenuItemModel
      },
      {
        id: 'library_biblio_blog',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.biblio-blog',
          function: () => this.openExternalURL('https://libguides.uprm.edu/blog')
        } as OnClickMenuItemModel
      },



      {
        id: 'library_databases',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.databases',
          function: () => this.openExternalURL('http://libguides.uprm.edu/az.php')
        } as OnClickMenuItemModel
      },
      {
        id: 'library_about_us',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.gric',
          function: () => this.openExternalURL('http://libguides.uprm.edu/gric')
        } as OnClickMenuItemModel
      },
      {
        id: 'library_liaison_librarians',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.liaison-librarians',
          function: () => this.openExternalURL('http://www.upr.edu/biblioteca-rum/bibenlace/')
        } as OnClickMenuItemModel
      },
      {
        id: 'library_hours-of-operation',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.hours-of-operation',
          function: () => this.openExternalURL('https://uprm.libcal.com/hours/')
        } as OnClickMenuItemModel
      },
      {
        id: 'library_upr-repository',
        parentID: 'library',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.library.upr-repository',
          function: () => this.openExternalURL('https://repositorio.upr.edu/')
        } as OnClickMenuItemModel
      },
      {
        id: 'help',
        active: false,
        visible: true,
        index: 5,
        model: {
          type: MenuItemType.TEXT,
          text: 'menu.section.help'
        } as TextMenuItemModel
      },
      {
        id: 'help_depositing-at-scholar',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.help.depositing-at-scholar',
          function: () => this.openExternalURL('https://libguides.uprm.edu/c.php?g=1238446')
        } as OnClickMenuItemModel
      },
      {
        id: 'help_repository-questions',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.help.repository-questions',
          function: () => this.openExternalURL('https://libguides.uprm.edu/gric/bibliotecarios')
        } as OnClickMenuItemModel
      },
      {
        id: 'help_ask-a-librarian',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.help.ask-a-librarian',
          function: () => this.openExternalURL('https://virtualref.uprm.edu/gric/')
        } as OnClickMenuItemModel
      },
      {
        id: 'help_guides',
        parentID: 'help',
        active: false,
        visible: true,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.help.guides',
          function: () => this.openExternalURL('https://libguides.uprm.edu/')
        } as OnClickMenuItemModel
      },
      {
        id: 'about-scholar',
        active: false,
        visible: true,
        index: 6,
        model: {
          type: MenuItemType.ONCLICK,
          text: 'menu.section.about-scholar',
          function: () => this.openExternalURL('https://libguides.uprm.edu/repositorioUPRM')
        } as OnClickMenuItemModel
      },
    ];
    // Read the different Browse-By types from config and add them to the browse menu
    this.browseService.getBrowseDefinitions()
      .pipe(getFirstCompletedRemoteData<PaginatedList<BrowseDefinition>>())
      .subscribe((browseDefListRD: RemoteData<PaginatedList<BrowseDefinition>>) => {
        if (browseDefListRD.hasSucceeded) {
          browseDefListRD.payload.page.forEach((browseDef: BrowseDefinition) => {
            menuList.push({
              id: `browse_global_by_Collection`,
              parentID: 'browse_global',
              active: false,
              visible: true,
              model: {
                type: MenuItemType.LINK,
                text: `menu.section.by_collection`,
                link: `/community-list`
              } as LinkMenuItemModel
            });
            menuList.push({
              id: `browse_global_by_${browseDef.id}`,
              parentID: 'browse_global',
              active: false,
              visible: true,
              model: {
                type: MenuItemType.LINK,
                text: `menu.section.browse_global_by_${browseDef.id}`,
                link: `/browse/${browseDef.id}`
              } as LinkMenuItemModel
            });
          });
          menuList.push(
            /* Browse */
            {
              id: 'browse_global',
              active: false,
              visible: true,
              index: 1,
              model: {
                type: MenuItemType.TEXT,
                text: 'menu.section.browse_global_communities_and_collections'
              } as TextMenuItemModel,
            }
          );
        }
        menuList.forEach((menuSection) => this.menuService.addSection(this.menuID, Object.assign(menuSection, {
          shouldPersistOnRouteChange: true
        })));
      });

  }

  /**
   * Opens an external url in a new tab.
   * @param url The destination url
   */
  openExternalURL(url: string): void {
    window.open(url);
  }
}
