/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../../../../../app/core/shared/item.model';
import { ItemCitationDataService } from '../../../../../core/data/item-citation-data.service';
import { CslStyleDataService } from '../../../../../core/data/csl-style-data.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CslStyle } from '../../../../../core/shared/csl-style.model';
import {
  getFirstSucceededRemoteData,
  getRemoteDataPayload
} from '../../../../../../app/core/shared/operators';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ItemCitation } from '../../../../../core/shared/item-citation.model';
import { FormControl } from '@angular/forms';
import { FindListOptions } from '../../../../../../app/core/data/request.models';

/**
 * Component for generating item citations.
 */
@Component({
  selector: 'ds-publication-csl-citation',
  templateUrl: './publication-csl-citation.component.html',
  styleUrls: ['./publication-csl-citation.component.scss']
})
export class PublicationCSLCitationComponent implements OnInit {

  /**
   * The item for which to generate citations.
   */
  @Input()
  item: Item;

  /**
   * A boolean indicating whether the citation section is hidden.
   */
  isCollapsed = true;

  /**
   * The search form control.
   * @type {FormControl}
   */
  searchField: FormControl = new FormControl();

  /**
   * The default item citation.
   */
  defaultItemCitation$: Observable<ItemCitation>;

  /**
   * The default citation title.
   */
  defaultCitationTitle: string;

  /**
   * The selected CSL Style.
   */
  selectedCslStyle: CslStyle;

  /**
   * The CSL Styles loaded from the backend.
   */
  cslStyles: CslStyle[] = [];

  /**
   * Parameters for retrieving the CSL styles from the backend.
   */
  getOptions: FindListOptions = {
    elementsPerPage: 50,
    currentPage: 1,
  };

  /**
   * Parameters for retrieving the CSL styles from the backend.
   */
  searchOptions: FindListOptions;

  /**
   * An observable boolean indicating whether new CSL styles are being loaded from the backend.
   */
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The current search term, as an observable.
   */
  searchTerm$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * The search results loaded from the backend.
   */
  searchResults: CslStyle[] = [];

  /**
   * The CSL styles to list in the dropdown, as an observable.
   */
  get stylesList(): CslStyle[] {
    if (this.searchTerm$.getValue()) {
      return this.searchResults;
    } else {
      return this.cslStyles;
    }
  }

  /**
   * The item citation to display, as an observable.
   */
  get itemCitation$(): Observable<ItemCitation> {
    return this.defaultItemCitation$.pipe(
      switchMap((defaultItemCitation) => {
        if (this.selectedCslStyle && this.selectedCslStyle.title !== defaultItemCitation.style) {
          return this.getItemCitation(this.selectedCslStyle.id);
        } else {
          return this.defaultItemCitation$;
        }
      })
    );
  }

  constructor(
    private cslStyleDataService: CslStyleDataService,
    private itemCitationDataService: ItemCitationDataService,
  ) {
  }

  ngOnInit() {

    this.defaultItemCitation$ = this.getItemCitation();

    this.getDefaultCitationTitle();

    this.getCslStyles();

    this.searchField.valueChanges.pipe(
      debounceTime(200),
    ).subscribe((searchTerm) =>
      this.searchTerm$.next(searchTerm)
    );

    this.searchTerm$.subscribe(() => {
      this.searchResults = [];
      this.searchOptions = {
        elementsPerPage: 20,
        currentPage: 1,
      };
      this.searchCslStyles();
    });

  }

  /**
   * Retrieve the next page of CSL styles for the dropdown list from the backend.
   */
  loadNextPage() {
    if (this.searchTerm$.getValue()) {
      this.searchCslStyles();
    } else {
      this.getCslStyles();
    }
  }

  /**
   * Retrieve a page of CSL styles from the backend and append it to the previously loaded CSL styles.
   */
  getCslStyles() {
    if (!this.loading$.getValue()) {
      this.loading$.next(true);
      this.cslStyleDataService.findAll(this.getOptions).pipe(
        getFirstSucceededRemoteData(),
        getRemoteDataPayload(),
        map((page) => page.page),
      ).subscribe(
        (cslStyles) => {
          this.loading$.next(false);
          this.cslStyles = this.cslStyles.concat(cslStyles);
          this.getOptions = Object.assign({}, this.getOptions, {
            currentPage: this.getOptions.currentPage + 1,
          });
        }
      );
    }
  }

  /**
   * Retrieve a page of search results from the backend and append it to the previously loaded search results.
   */
  searchCslStyles() {
    if (!this.loading$.getValue()) {
      this.loading$.next(true);
      this.cslStyleDataService.searchCitationStyles(this.searchTerm$.getValue(), this.searchOptions)
        .subscribe((results) => {
          this.loading$.next(false);
          this.searchResults = this.searchResults.concat(results);
          this.searchOptions = Object.assign({}, this.searchOptions, {
            currentPage: this.searchOptions.currentPage + 1,
          });
        });
    }
  }

  /**
   * Select a given CSL style.
   * @param cslStyle  the CSL style to select.
   */
  selectCslStyle(cslStyle: CslStyle): void {
    this.selectedCslStyle = cslStyle;
    this.searchField.reset();
  }

  /**
   * Retrieve the item citation for a given format from the backend.
   * If no format is specified, the default item citation is fetched.
   * @param format  The format to retrieve an item citation for (optional).
   */
  getItemCitation(format?: string): Observable<ItemCitation> {
    return this.itemCitationDataService.getItemCitation(this.item._links.self.href, format);
  }

  getDefaultCitationTitle() {
    this.defaultItemCitation$.subscribe(
      (itemCitation) => {
        this.cslStyleDataService.findByHref(itemCitation._links.cslstyle.href).subscribe((cslStyle) => { this.defaultCitationTitle = cslStyle.payload.title;});
      }
    );
  }
}
