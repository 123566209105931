<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="cite btn btn-outline-danger mt-1 mb-1" (click)="isCollapsed = !isCollapsed">
  {{ 'publication-cite.toggle' | translate }}
</div>

<div [ngbCollapse]="isCollapsed" class="mt-1 mb-1">

  <ds-atmire-select class="citation-select">
    <ng-container *ngVar="defaultItemCitation$ | async as defaultItemCitation"
                  class="selection">
      <ng-container *ngIf="!defaultItemCitation">
        <i class='fas fa-circle-notch fa-spin'></i>
      </ng-container>
      <ng-container *ngIf="defaultItemCitation && !selectedCslStyle">
        {{ defaultCitationTitle }}
      </ng-container>
      <ng-container *ngIf="selectedCslStyle">
        {{ selectedCslStyle?.title }}
      </ng-container>
    </ng-container>
    <ng-container class="menu">
      <div class="dropdown-header" (click)="$event.stopPropagation()">
        <input type="search"
               class="form-control"
               (click)="$event.stopPropagation()"
               placeholder="{{ 'publication-cite.search' | translate }}"
               [formControl]="searchField">
      </div>
      <ng-container>
        <div class="scrollable-menu"
             infiniteScroll
             [scrollWindow]="false"
             (scrolled)="loadNextPage()">
          <button *ngFor="let cslStyle of stylesList"
                  ngbDropdownItem
                  (click)="selectCslStyle(cslStyle)">
            {{ cslStyle.title }}
          </button>
          <div *ngIf="loading$ | async"
               class="dropdown-item">
            <i class='fas fa-circle-notch fa-spin'></i>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ds-atmire-select>

  <ng-container *ngVar="(itemCitation$ | async) as itemCitation">
    <div class="citation m-1">
      <ng-container *ngIf="!itemCitation">
        <i class='fas fa-circle-notch fa-spin'></i>
      </ng-container>
      <div [innerHTML]="itemCitation?.citation"></div>
    </div>
  </ng-container>

</div>
