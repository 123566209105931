import { Component, Input, OnInit} from '@angular/core';
import {Item} from '../../core/shared/item.model';

@Component({
  selector: 'ds-cc-license',
  styleUrls: ['./cc-license.component.scss'],
  templateUrl: './cc-license.component.html',
})
export class CCLicenseComponent implements OnInit {

  @Input()
  item: Item;
  ccLicenseName: string;
  ccLicenseURI: string;
  ccRightsLicense: string;
  imgSource: string;
  ccLicenseText: string;

  ngOnInit(): void {
    this.ccLicenseName = this.item.firstMetadata('dc.rights')?.value;
    this.ccLicenseURI = this.item.firstMetadata('dc.rights.uri')?.value;
    this.ccRightsLicense = this.item.firstMetadata('dc.rights.license')?.value;

    if (this.ccLicenseURI != null) {
      this.ccLicenseText = this.ccLicenseName;
      switch (this.ccLicenseURI.split('/')[4]) {
        case 'by':
          this.imgSource = 'assets/images/cc-by.png';
          break;
        case 'by-nc':
          this.imgSource = 'assets/images/cc-by-nc.png';
          break;
        case 'by-nc-nd':
          this.imgSource = 'assets/images/cc-by-nc-nd.png';
          break;
        case 'by-nc-sa':
          this.imgSource = 'assets/images/cc-nc-sa.png';
          break;
        case 'by-nd':
          this.imgSource = 'assets/images/cc-by-nd.png';
          break;
        case 'by-sa':
          this.imgSource = 'assets/images/cc-by-sa.png';
          break;
        case 'mark':
          this.imgSource = 'assets/images/cc-mark.png';
          break;
        case 'zero':
          this.imgSource = 'assets/images/cc-zero.png';
          break;
        default:
          this.imgSource = 'assets/images/all-rights.png';
          break;
      }
    } else if (this.ccRightsLicense != null) {
      this.ccLicenseText = this.ccRightsLicense;
      this.imgSource = 'assets/images/all-rights.png';
    } else {
      this.ccLicenseText = 'All rights reserved';
      this.imgSource = 'assets/images/all-rights.png';
    }
  }



}
