/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { deserialize, autoserialize } from 'cerialize';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';
import { HALLink } from '../../../app/core/shared/hal-link.model';
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { ITEM_CITATION } from './item-citation.resource-type';
import { HALResource } from '../../../app/core/shared/hal-resource.model';
import { ResourceType } from '../../../app/core/shared/resource-type';

/**
 * Class representing an Item Citation
 */
@typedObject
export class ItemCitation extends HALResource {

  static type = ITEM_CITATION;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @autoserialize
  style: string;

  @autoserialize
  citation: string;

  /**
   * The {@link HALLink}s for this {@link HALResource}
   */
  @deserialize
  _links: {
    /**
     * The {@link HALLink} that refers to this {@link HALResource}
     */
    self: HALLink

    /**
     * The {@link HALLink}s that refers to the corresponding CSL style
     */
    cslstyle: HALLink;
  };
}
