/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { autoserialize, deserialize } from 'cerialize';
import { excludeFromEquals } from '../../../app/core/utilities/equals.decorators';
import { typedObject } from '../../../app/core/cache/builders/build-decorators';
import { CSL_STYLE } from './csl-style.resource-type';
import { ResourceType } from '../../../app/core/shared/resource-type';
import { HALResource } from '../../../app/core/shared/hal-resource.model';
import { HALLink } from '../../../app/core/shared/hal-link.model';

/**
 * Class representing a CSL style
 */
@typedObject
export class CslStyle extends HALResource {

  static type = CSL_STYLE;

  /**
   * The object type
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;

  @autoserialize
  id: string;

  @autoserialize
  title: string;

  @autoserialize
  titleShort: string;

  /**
   * The {@link HALLink}s for this {@link HALResource}
   */
  @deserialize
  _links: {
    /**
     * The {@link HALLink} that refers to this {@link HALResource}
     */
    self: HALLink
  };
}
