import { Component, Renderer2, ViewChild } from '@angular/core';
import { Event } from '@angular/router';
import { HeaderComponent as BaseComponent } from '../../../../app/header/header.component';
import { MenuService } from '../../../../app/shared/menu/menu.service';

/**
 * Represents the header with the logo and simple navigation
 */
@Component({
  selector: 'ds-header',
  styleUrls: ['header.component.scss'],
  templateUrl: 'header.component.html',
})
export class HeaderComponent extends BaseComponent {

  @ViewChild('slideout') element;

  @ViewChild('load') load;

  isopen = false;

  constructor(private renderer: Renderer2,
              menuService: MenuService) {
    super(menuService);
  }


  openPopUp(event: any) {
    event.preventDefault();
    console.log(this.element);
    if (!this.isopen) {
      this.renderer.setStyle(this.element.nativeElement, 'right', '0');
      this.renderer.setStyle(this.load.nativeElement, 'display', 'block');
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'right', '-400px');
      this.renderer.setStyle(this.load.nativeElement, 'display', 'none');
    }
    this.isopen = !this.isopen;
  }
}
