/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { CslStyleDataService } from './core/data/csl-style-data.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Action, StoreConfig, StoreModule } from '@ngrx/store';
import { coreReducers, CoreState } from '../app/core/core.reducers';
import { storeModuleConfig } from '../app/app.reducer';
import { ItemCitationDataService } from './core/data/item-citation-data.service';
import { SharedModule } from '../app/shared/shared.module';
import { DsAtmireSelectComponent } from './shared/ds-select/ds-atmire-select.component';

const IMPORTS = [
  CommonModule,
  SharedModule,
  StoreModule.forFeature('core', coreReducers, storeModuleConfig as StoreConfig<CoreState, Action>),
];

const DECLARATIONS = [
];

const EXPORTS = [
];

const COMPONENTS = [
  DsAtmireSelectComponent
];

const PROVIDERS = [
  CslStyleDataService,
  ItemCitationDataService,
];

export const MODELS = [
];

@NgModule({
  imports: [
    ...IMPORTS
  ],
  declarations: [
    ...DECLARATIONS,
    ...COMPONENTS
  ],
  exports: [
    ...EXPORTS,
    DsAtmireSelectComponent
  ],
  providers: [
    ...PROVIDERS
  ]
})

export class CslModule {
  static forRoot(): ModuleWithProviders<CslModule> {
    return {
      ngModule: CslModule,
      providers: [
        ...PROVIDERS
      ]
    };
  }
}


