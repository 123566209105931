/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { DataService } from '../../../app/core/data/data.service';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { FindListOptions } from '../../../app/core/data/request.models';
import { Observable } from 'rxjs/internal/Observable';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { ITEM_CITATION } from '../shared/item-citation.resource-type';
import { ItemCitation } from '../shared/item-citation.model';
import { getRemoteDataPayload, getFirstSucceededRemoteData } from '../../../app/core/shared/operators';

/**
 * Service responsible for handling requests related to the Item Citation object
 */
@Injectable()
@dataService(ITEM_CITATION)
export class ItemCitationDataService extends DataService<ItemCitation> {
  protected linkPath = 'citation';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<ItemCitation>) {
    super();
  }

  /**
   * Get the endpoint for browsing item citations.
   */
  getBrowseEndpoint(options: FindListOptions = {}, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }

  /**
   * Get the citation for a given item in the given format.
   * @param itemLink  the item to get a citation for
   * @param format    the citation format
   */
  getItemCitation(itemLink: string, format: string): Observable<ItemCitation> {

    if (itemLink.includes('?savedList=')) {
      itemLink = itemLink.split('?savedList=')[0];
    }

    return this.findByHref(`${itemLink}/citation?` + (format ? `format=${format}` : ``)).pipe(
      getFirstSucceededRemoteData(),
      getRemoteDataPayload(),
    );
  }
}
