/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Injectable } from '@angular/core';
import { DataService } from '../../../app/core/data/data.service';
import { RequestService } from '../../../app/core/data/request.service';
import { RemoteDataBuildService } from '../../../app/core/cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../../../app/core/core.reducers';
import { ObjectCacheService } from '../../../app/core/cache/object-cache.service';
import { HALEndpointService } from '../../../app/core/shared/hal-endpoint.service';
import { NotificationsService } from '../../../app/shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../../../app/core/data/default-change-analyzer.service';
import { FindListOptions } from '../../../app/core/data/request.models';
import { Observable } from 'rxjs/internal/Observable';
import { dataService } from '../../../app/core/cache/builders/build-decorators';
import { CslStyle } from '../shared/csl-style.model';
import { CSL_STYLE } from '../shared/csl-style.resource-type';
import { getRemoteDataPayload, getFirstSucceededRemoteData } from '../../../app/core/shared/operators';
import { map } from 'rxjs/operators';

/**
 * Service responsible for handling requests related to the CSL style object
 */
@Injectable()
@dataService(CSL_STYLE)
export class CslStyleDataService extends DataService<CslStyle> {
  protected linkPath = 'cslstyles';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<CslStyle>) {
    super();
  }

  /**
   * Get the endpoint for browsing csl styles.
   */
  getBrowseEndpoint(options: FindListOptions = {}, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }

  /**
   * Search citation styles
   * @param query     the search query
   * @param options   search parameters
   */
  searchCitationStyles(query: string, options: FindListOptions): Observable<CslStyle[]> {
    return this.searchBy('objects', Object.assign({
      searchParams: [{
        fieldName: 'query',
        fieldValue: query,
      }],
    }, options)).pipe(
      getFirstSucceededRemoteData(),
      getRemoteDataPayload(),
      map((list) => list.page),
    );
  }
}
