/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Component which represent a DSpace dropdown selector.
 */
@Component({
  selector: 'ds-atmire-select',
  templateUrl: './ds-atmire-select.component.html',
  styleUrls: ['./ds-atmire-select.component.scss']
})
export class DsAtmireSelectComponent {

  /**
   * An optional label for the dropdown selector.
   */
  @Input()
  label: string;

  /**
   * Whether the dropdown selector is disabled.
   */
  @Input()
  disabled: boolean;

  /**
   * Emits an event when the dropdown selector is opened or closed.
   */
  @Output()
  toggled = new EventEmitter();

  /**
   * Emits an event when the dropdown selector or closed.
   */
  @Output()
  close = new EventEmitter();
}
