<ds-metadata-field-wrapper [label]="label | translate">

  <ng-container *ngIf="mdValues.length === 0">
    <span class="dont-break-out">
      {{defaultLabel | translate}}
    </span>
  </ng-container>

  <span class="dont-break-out" *ngFor="let mdValue of mdValues; let last=last;">
      {{mdValue.value}}<span *ngIf="!last" [innerHTML]="separator"></span>
      <br>
  </span>
</ds-metadata-field-wrapper>
