<div class="homePageSearch">
  <div class="homePageSearchContainer">
    <h2 class="homePageSearchHeader">{{ 'homepage.search' | translate}}</h2>
    <form #form="ngForm" (ngSubmit)="onSubmit(form.value)" class="row homePageSearchForm" action="/search">
    <div *ngIf="isNotEmpty(scopes)" class="col-12 col-sm-3">
      <select [(ngModel)]="scope" name="scope" class="form-control" aria-label="Search scope" (change)="onScopeChange($event.target.value)">
        <option value>{{'search.form.search_dspace' | translate}}</option>
        <option *ngFor="let scopeOption of scopes" [value]="scopeOption.id">{{scopeOption?.name ? scopeOption.name : 'search.form.search_dspace' | translate}}</option>
      </select>
    </div>
        <div [ngClass]="{'col-sm-9': isNotEmpty(scopes)}" class="col-12">
        <div class="form-group input-group">
            <input type="text" [(ngModel)]="query" name="query" class="form-control" aria-label="Search input">
            <span class="input-group-append">
                <button type="submit" class="search-button btn btn-secondary">{{ ('search.form.search' | translate) }}</button>
            </span>
        </div>
        </div>
    </form>
    <p>{{'homepage.welcome' | translate}}</p>
  </div>
</div>





